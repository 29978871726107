import { Pin, Switchboard } from '@/interfaces/Files.ts';
import { MutableRefObject } from 'react';

export const drawOnCanvas = (
  pageData: any,
  pdfWidth: number,
  canvasRef: MutableRefObject<HTMLCanvasElement | null>
) => {
  const canvas = canvasRef.current;
  const context = canvas?.getContext('2d');

  if (!canvas || !context) {
    return;
  }

  const currentPage = pageData; // Assuming first page
  if (!currentPage) {
    console.error('Page data is empty or undefined');
    return;
  }

  const { width, height, list_idx, switchboards, extras } = currentPage;

  const scaleFactor = pdfWidth / width;

  const newWidth = Math.round(width * scaleFactor);
  const newHeight = Math.round(height * scaleFactor);

  canvas.width = newWidth;
  canvas.height = newHeight;
  canvas.style.width = `${newWidth}px`;
  canvas.style.height = `${newHeight}px`;

  context.clearRect(0, 0, canvas.width, canvas.height);

  context.imageSmoothingEnabled = false;

  const scaleX = newWidth / width;
  const scaleY = newHeight / height;

  // Draw `list_idx.area`
  if (list_idx?.area) {
    drawListIdx(context, list_idx.area, scaleX, scaleY);
  }

  // Draw extras
  if (extras) {
    drawExtras(context, extras, scaleX, scaleY);
  }

  // Draw switchboards and pins
  switchboards.forEach((switchboard: any) => {
    drawSwitchboard(context, switchboard, scaleX, scaleY);

    if (switchboard.pins) {
      drawPins(context, switchboard.pins, scaleX, scaleY);
    }
  });
};

function drawListIdx(context: CanvasRenderingContext2D, area: any, scaleX: number, scaleY: number) {
  const { x, y, width, height } = area;

  const scaledX = Math.round(x * scaleX);
  const scaledY = Math.round(y * scaleY);
  const scaledWidth = width * scaleX;
  const scaledHeight = height * scaleY;

  // Draw list_idx area
  context.strokeStyle = 'rgba(102, 178, 255, 1)';
  context.lineWidth = 3;
  context.fillStyle = 'rgba(102, 178, 255, 0.01)';
  context.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
  context.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
}

function drawExtras(context: CanvasRenderingContext2D, extras: any[], scaleX: number, scaleY: number) {
  extras.forEach((extra) => {
    const { object } = extra;
    if (object && object.area) {
      const { x, y, width, height } = object.area;
      const scaledX = Math.round(x * scaleX);
      const scaledY = Math.round(y * scaleY);
      const scaledWidth = width * scaleX;
      const scaledHeight = height * scaleY;

      context.strokeStyle = 'rgba(0, 100, 200, 1)';
      context.lineWidth = 2;
      context.fillStyle = 'rgba(0, 100, 200, 0.01)';
      context.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
      context.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
    }
  });
}

function drawSwitchboard(context: CanvasRenderingContext2D, switchboard: Switchboard, scaleX: number, scaleY: number) {
  const { shape, identifier } = switchboard;

  // Draw shape points if present
  if (shape?.points && shape.points.length > 0) {
    context.strokeStyle = 'rgba(150, 0, 0, 1)';
    context.lineWidth = 2;
    context.fillStyle = 'rgba(150, 0, 0, 0.01)';

    context.beginPath();
    shape.points.forEach((point: any, index: any) => {
      const scaledX = point.x * scaleX;
      const scaledY = point.y * scaleY;
      if (index === 0) {
        context.moveTo(scaledX, scaledY);
      } else {
        context.lineTo(scaledX, scaledY);
      }
    });
    context.closePath();
    context.stroke();
    context.fill();
  }

  // Draw identifier area
  if (identifier?.area) {
    const { x, y, width, height } = identifier.area;
    const scaledX = Math.round(x! * scaleX);
    const scaledY = Math.round(y! * scaleY);
    const scaledWidth = width! * scaleX;
    const scaledHeight = height! * scaleY;

    context.strokeStyle = 'rgba(150, 0, 0, 1)';
    context.lineWidth = 2;
    context.fillStyle = 'rgba(150, 0, 0, 0.01)';
    context.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
    context.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
  }
}

function drawPins(context: CanvasRenderingContext2D, pins: any, scaleX: number, scaleY: number) {
  // Define a color map for each pin category
  const colorMap: Record<string, { stroke: string; fill: string }> = {
    signal_name: { stroke: 'rgba(255, 0, 0, 1)', fill: 'rgba(255, 0, 0, 0.01)' },
    signal_type: { stroke: 'rgba(0, 255, 0, 1)', fill: 'rgba(0, 255, 0, 0.01)' },
    connector_name: { stroke: 'rgba(0, 0, 255, 1)', fill: 'rgba(0, 0, 255, 0.01)' },
    line_number: { stroke: 'rgba(255, 165, 0, 1)', fill: 'rgba(255, 165, 0, 0.01)' },
    line_shape: { stroke: 'rgba(128, 0, 128, 1)', fill: 'rgba(128, 0, 128, 0.01)' },
  };

  pins.forEach((pin: any) => {
    // Iterate through each pin category
    Object.keys(colorMap).forEach((key) => {
      const pinComponent = pin[key as keyof Pin] as { area: any; name: string } | undefined;

      if (pinComponent?.area) {
        const { x, y, width, height } = pinComponent.area;
        const scaledX = Math.round(x * scaleX);
        const scaledY = Math.round(y * scaleY);
        const scaledWidth = width * scaleX;
        const scaledHeight = height * scaleY;

        const colors = colorMap[key]; // Get colors for this category
        context.strokeStyle = colors.stroke;
        context.fillStyle = colors.fill;

        context.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
        context.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);
      }
    });

    // Handle line_shape differently as it involves line points
    if (pin.line_shape?.area?.linepoints) {
      const colors = colorMap.line_shape;
      context.strokeStyle = colors.stroke;
      context.lineWidth = 2;

      context.beginPath();
      pin.line_shape.area.linepoints.forEach((point: any, index: any) => {
        const scaledX = Math.round(point.x * scaleX);
        const scaledY = Math.round(point.y * scaleY);
        if (index === 0) {
          context.moveTo(scaledX, scaledY);
        } else {
          context.lineTo(scaledX, scaledY);
        }
      });
      context.stroke();
    }
  });
}
