import api from '@/api/api.ts';
import { downloadFile, downloadOriginalFile, getLastUpdate, getProgress } from '@/api/services/File.ts';
import { PageLoader } from '@/components/PageLoader.tsx';
import FileTable from '@/components/fileTable.tsx';
import { useCachedFile } from '@/hooks/useCachedFile.ts';
import { useCachedFileData } from '@/hooks/useCachedFileData.ts';
import { drawOnCanvas } from '@/utils/drawCanvas.ts';
import { createFileRoute, useNavigate, useSearch } from '@tanstack/react-router';
import {
  ClipboardCopy,
  ClipboardPaste,
  Edit,
  File,
  FileChartColumn,
  FileCode,
  FileText,
  PlusIcon
} from 'lucide-react';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { ProgressBar } from 'primereact/progressbar';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import { toast } from 'react-toastify';

import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { formatDate } from '@/hooks/useDate.ts';
import { useApiGet } from '@/api/apiRequests.ts';
import { AxiosError } from 'axios';
import {Checkbox, CheckboxChangeEvent} from "primereact/checkbox";

pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();

export const FilePage: FC = () => {
  const { fileId } = Route.useParams();
  const [numPages, setNumPages] = useState<number | null>(null);
  const navigate = useNavigate();
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const pdfWidth = 1120;
  const [renameDialogVisible, setRenameDialogVisible] = useState(false);
  const [renameFile, setRenameFile] = useState({ filename: '' });
  const [newSwitchboardDialogVisible, setNewSwitchboardDialogVisible] = useState(false);
  const [newSwitchboard, setNewSwitchboard] = useState({ identifier_name: '' });
  const [scale, setScale] = useState(1.0);
  const [zoomOrigin, setZoomOrigin] = useState({ x: 0, y: 0 });
  const [zoomed, setZoomed] = useState(false);
  const containerRef = useRef<any>(null);
  const [processingProgress, setProcessingProgress] = useState(1);
  const [isProcessingComplete, setIsProcessingComplete] = useState(false);
  const [loadedPages, setLoadedPages] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isInitialStatusSet, setIsInitialStatusSet] = useState(false);
  const search = useSearch({ from: `/file/$fileId/` }) as { page?: string };
  const initialPageNumber = search.page ? parseInt(search.page, 10) : 1;
  const [pageNumber, setPageNumber] = useState(1);
  const [lastUpdateDate, setLastUpdateDate] = useState<string>('');
  const [lastUpdateAuthor, setLastUpdateAuthor] = useState<string>('');
  const [pasteDialogVisible, setPasteDialogVisible] = useState(false);
  const [withData, setWithData] = useState(false);
  const [pasteData, setPasteData] = useState<{ source_doc_uid: string; source_page_number: number } | null>(null);

  const {
    file,
    isLoading: fileLoading,
    isCacheChecked: isCacheFileChecked,
    compareAndFetchFile,
  } = useCachedFile(fileId, pageNumber - 1);
  const {
    fileData,
    isLoading: fileDataLoading,
    compareAndFetch,
    refetch,
    error: dataError,
  } = useCachedFileData(fileId, pageNumber - 1);

  const { data: progressData, refetch: refetchProgress } = useApiGet<any, AxiosError>(
    ['pagesProgress', fileId],
    async () => await getProgress(fileId)
  );

  // Add this useEffect to ensure page number is set correctly from URL on initial load
  useEffect(() => {
    if (search.page) {
      const pageFromUrl = parseInt(search.page, 10);
      // biome-ignore lint/style/noRestrictedGlobals: <explanation>
      if (!isNaN(pageFromUrl) && pageFromUrl > 0) {
        setPageNumber(pageFromUrl);
      }
    }
  }, [search.page]);

  useEffect(() => {
    const fetchLastUpdate = async () => {
      try {
        const lastUpdate = await getLastUpdate(fileId);
        setLastUpdateDate(formatDate(lastUpdate.date));
        setLastUpdateAuthor(lastUpdate.author);
      } catch (error) {
        console.error('Error fetching last updated:', error);
      }
    };

    // Fetch data immediately on page load
    fetchLastUpdate();

    // Set up interval to fetch data every 30 seconds
    const intervalId = setInterval(fetchLastUpdate, 30000);

    return () => clearInterval(intervalId);
  }, [fileId]);

  // Handle 404 errors
  useEffect(() => {
    if (dataError?.response?.status === 404) {
      navigate({ to: '/error' });
    }
  }, [dataError, navigate]);

  useEffect(() => {
    const checkAndUpdateStatus = async () => {
      if (!isInitialStatusSet && !fileDataLoading) {
        await compareAndFetch();
        if (fileData) {
          setIsInitialStatusSet(true);
        }
      }
    };

    checkAndUpdateStatus();
  }, [isInitialStatusSet, fileDataLoading, compareAndFetch, fileData]);

  // Trigger updates when file or metadata changes
  useEffect(() => {
    if (isCacheFileChecked && !file && !fileLoading) {
      compareAndFetchFile(); // Ensure file is loaded
    }
  }, [isCacheFileChecked, file, fileLoading, compareAndFetchFile]);

  const getCurrentPageData = useCallback(() => {
    if (!fileData) return null;
    if (fileData.page_data.page_number === pageNumber - 1) {
      return fileData.page_data;
    }
    return null;
  }, [fileData, pageNumber]);

  const clearCanvas = useCallback(() => {
    if (canvasRef.current) {
      const ctx = canvasRef.current.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
      }
    }
  }, []);

  // Draw data on canvas whenever fileData or pageNumber changes
  useEffect(() => {
    const pageData = getCurrentPageData();
    if (pageData && canvasRef.current) {
      if (pageData.status === 'success') {
        drawOnCanvas(pageData, pdfWidth, canvasRef);
      } else {
        clearCanvas(); // Clear the canvas if the page data is not successful
      }
    }
  }, [getCurrentPageData, clearCanvas, pdfWidth]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout | null = null;

    const checkProcessingStatus = async () => {
      if (!progressData) return false;

      // Use refetchProgress to get the latest progress data
      await refetchProgress();

      if (progressData) {
        const successfulPages = progressData.finished || 0;
        const totalPages = progressData.total || 0;
        const allPagesProcessed = successfulPages === totalPages;

        setLoadedPages(successfulPages);
        setTotalPages(totalPages);
        setProcessingProgress(Math.round((successfulPages / totalPages) * 100));

        // Only set isProcessingComplete to true if ALL pages are processed
        setIsProcessingComplete(allPagesProcessed);

        return allPagesProcessed;
      }
      return false;
    };

    const startInterval = () => {
      checkProcessingStatus();

      intervalId = setInterval(async () => {
        const allProcessed = await checkProcessingStatus();
        if (allProcessed) {
          clearInterval(intervalId!);
        }
      }, 15000);
    };

    if (progressData) {
      startInterval();
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [progressData, refetchProgress]);

  const handleResetZoom = useCallback(() => {
    setScale(1.0);
    setZoomed(false);
    setZoomOrigin({ x: 0, y: 0 });
    if (containerRef.current) {
      containerRef.current.scrollTop = 0;
      containerRef.current.scrollLeft = 0;
    }
  }, []);

  const handlePdfClick = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      if (!containerRef.current) return;

      const container = containerRef.current;
      const rect = container.getBoundingClientRect();
      const x = event.clientX - rect.left + container.scrollLeft;
      const y = event.clientY - rect.top + container.scrollTop;

      if (zoomed) {
        // If already zoomed, reset to normal view
        handleResetZoom();
      } else {
        // If not zoomed, zoom in
        setScale(5.0);
        setZoomed(true);
        setZoomOrigin({ x, y });

        container.scrollLeft = x * 5 - container.offsetWidth / 2;
        container.scrollTop = y * 5 - container.offsetHeight / 2;
      }
    },
    [zoomed, handleResetZoom]
  );

  const onDocumentLoadSuccess = ({ numPages: total }: { numPages: number }) => {
    setNumPages(total);
  };

  useEffect(() => {
    // Ensure the page number is within valid range when numPages changes
    if (numPages) {
      if (pageNumber < 1) {
        setPageNumber(1);
        navigate({
          to: `/file/${fileId}`,
          search: { page: 1 },
        });
      } else if (pageNumber > numPages) {
        setPageNumber(1);
        navigate({
          to: `/file/${fileId}`,
          search: { page: 1 },
        });
      } else if (pageNumber !== initialPageNumber && initialPageNumber >= 1 && initialPageNumber <= numPages) {
        // If URL parameter is valid but different from current state, update state
        setPageNumber(initialPageNumber);
      }
    }
  }, [initialPageNumber, numPages, pageNumber, navigate, fileId]);

  const changePage = useCallback(
    async (newPage: number) => {
      if (newPage >= 1 && newPage <= (numPages || 1)) {
        setPageNumber(newPage);
        clearCanvas(); // Clear the canvas when changing pages

        // Update the URL with the new page parameter
        navigate({
          to: `/file/${fileId}`,
          search: { page: newPage },
        });
        await refetch();
      }
    },
    [numPages, clearCanvas, navigate, fileId, refetch]
  );

  const copyPageInfo = () => {
    const pageInfo = JSON.stringify({ source_doc_uid: fileId, source_page_number: pageNumber - 1 });
    navigator.clipboard.writeText(pageInfo);
    toast.success('Data úspěšně zkopírována.');
  };

  const openPasteDialog = async () => {
    const clipboardContent = await navigator.clipboard.readText();
    try {
      const parsedData = JSON.parse(clipboardContent);
      if (parsedData.source_doc_uid && typeof parsedData.source_page_number === 'number') {
        setPasteData(parsedData);
        setPasteDialogVisible(true);
      } else {
        toast.error('Špatná data ze schránky.');
      }
    } catch (error) {
      console.error('Failed to parse clipboard data:', error);
      toast.error('Chyba při zpracování dat ze schránky.');
    }
  };

  const handlePasteData = async () => {
    if (!pasteData) return;

    try {
      const response = await api.patch('/copy-page', {
        source_doc_uid: pasteData.source_doc_uid,
        source_page_number: pasteData.source_page_number,
        target_doc_uid: fileId,
        target_page_number: pageNumber - 1,
        with_graphics: withData
      });

      if (response.status === 200 || response.status === 202) {
        toast.success('Data byly úspěšny nakopírovány.');
        setPasteDialogVisible(false);
        setPasteData(null);
        refetch();
      }
    } catch (error) {
      console.error('Failed to copy page:', error);
      toast.error('Chyba při kopírování dat na stránku.');
    }
  };

  const openNewSwitchboardDialog = () => {
    setNewSwitchboardDialogVisible(true);
  };

  const addNewSwitchboard = async () => {
    try {
      const response = await api.post(`add/switchboard/${fileData?.page_data.file_uid}/${pageNumber - 1}`, {
        ...newSwitchboard,
      });
      if (response.status === 200) {
        toast.success('Nový switchboard úspěšně přidán.');
        setNewSwitchboardDialogVisible(false);
        setNewSwitchboard({ identifier_name: '' });
        await refetch();
        setPageNumber((prevPageNumber) => prevPageNumber);
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při ukládání switchboardu.`);
      }
    }
  };

  const renameFileAction = async () => {
    try {
      const response = await api.put(`rename/${fileData?.page_data.file_uid}`, { ...renameFile });
      if (response.status === 200 || response.status === 202) {
        toast.success('Hotovo. Soubor byl přejmenován.');
        setRenameDialogVisible(false);
        setRenameFile({ filename: '' });
        await refetch();
        setPageNumber((prevPageNumber) => prevPageNumber);
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při změně názvu souboru.`);
      }
    }
  };

  return (
    <>
      {fileData && !isProcessingComplete && (
        <div className={'pageProgress flex items-center gap-4 w-full p-4'}>
          <p className={'mb-0 inline-block flex-1'}>
            Zpracované stránky: <strong>{loadedPages}</strong> z <strong>{totalPages}</strong>
          </p>
          <ProgressBar
            className={`flex-1 opacity-80 ${isProcessingComplete ? 'isFull' : ''}`}
            style={{ height: '1.35rem', width: '100%' }}
            value={processingProgress}
          />
        </div>
      )}
      <div className={`flex justify-between items-center gap-2 w-full ${!isProcessingComplete ? 'mt-12' : ''}`}>
        <h1 className={'text-xl flex items-center gap-2'}>
          Soubor: <span className={'font-bold'}>{fileData?.filename}</span>
          <Button
            icon={<Edit size={16} />}
            text={true}
            rounded={true}
            severity={'info'}
            tooltip={'Přejmenovat soubor'}
            tooltipOptions={{ position: 'right' }}
            onClick={() => setRenameDialogVisible(true)}
          />
        </h1>
        {fileData && (
          <div className={'flex items-center gap-3'}>
            <span>Export:</span>
            <Button
              icon={<FileChartColumn size={16} />}
              rounded={true}
              outlined={true}
              severity={'success'}
              tooltip={'.xlsx'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => downloadFile(fileId, 'xlsx')}
            />
            <Button
              icon={<FileCode size={16} />}
              rounded={true}
              outlined={true}
              severity={'warning'}
              tooltip={'.ini'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => downloadFile(fileId, 'ini')}
            />
            <Button
              icon={<FileText size={16} />}
              rounded={true}
              outlined={true}
              severity={'warning'}
              tooltip={'.pdf'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => downloadFile(fileId, 'pdf')}
            />
            <Button
              icon={<File size={16} />}
              rounded={true}
              outlined={true}
              severity={'help'}
              tooltip={'Původní .pdf'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              onClick={() => downloadOriginalFile(fileId)}
            />
            <span className={'w-0 h-8 border-l border-gray-200 mx-1'} />
            <Button
              icon={<ClipboardCopy size={16} />}
              rounded={true}
              outlined={true}
              severity={'secondary'}
              onClick={copyPageInfo}
              tooltip={'Zkopírovat stránku'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              className={'text-sm'}
            />
            <Button
              icon={<ClipboardPaste size={16} />}
              rounded={true}
              outlined={true}
              severity={'info'}
              onClick={openPasteDialog}
              tooltip={'Vložit data'}
              tooltipOptions={{ position: 'top', mouseTrack: true, mouseTrackTop: 15 }}
              className={'text-sm'}
            />
          </div>
        )}
      </div>
      <div className={'flex flex-wrap items-start gap-4 justify-center w-full mt-8'}>
        <div className={'flex flex-col items-center gap-4'}>
          <div className={`relative overflow-hidden max-w-full`} style={{ width: `${pdfWidth}px`, height: '806px' }}>
            <PageLoader visible={!fileData} />
            {file && (
              // biome-ignore lint/a11y/useKeyWithClickEvents: <explanation>
              <div
                ref={containerRef}
                style={{
                  overflow: 'hidden',
                  cursor: zoomed ? 'zoom-out' : 'zoom-in',
                  position: 'relative',
                }}
                onClick={handlePdfClick}
              >
                <div
                  style={{
                    transform: `scale(${scale})`,
                    transformOrigin: `${zoomOrigin.x}px ${zoomOrigin.y}px`,
                    transition: 'transform 0.3s ease-in-out', // Smooth zoom transition
                  }}
                >
                  <Document
                    file={file}
                    loading={<PageLoader visible={!fileData} />}
                    onLoadSuccess={onDocumentLoadSuccess}
                    onLoadError={(error) => console.error('PDF Load Error:', error)}
                  >
                    <div className={'relative'}>
                      <Page
                        pageNumber={pageNumber}
                        width={pdfWidth}
                        onLoadSuccess={(page) => {
                          // Ensure page dimensions are set
                          const { width, height } = page;
                          if (canvasRef.current) {
                            canvasRef.current.style.width = `${width}px`;
                            canvasRef.current.style.height = `${height}px`;
                            // Trigger redraw when Page is loaded
                            const pageData = getCurrentPageData();
                            if (pageData) {
                              drawOnCanvas(pageData, pdfWidth, canvasRef);
                            }
                          }
                        }}
                      >
                        <canvas
                          ref={canvasRef}
                          style={{ position: 'absolute', top: '0', left: '0', pointerEvents: 'none' }}
                        />
                      </Page>
                    </div>
                  </Document>
                </div>
              </div>
            )}
          </div>
          <div className={'page-controls'}>
            <Button className={'btn-blue'} onClick={() => changePage(pageNumber - 1)} disabled={pageNumber <= 1}>
              Předchozí
            </Button>
            <div className={'flex items-center gap-2'}>
              <InputNumber
                className={'numberInput'}
                name={'pageNumber'}
                value={pageNumber}
                max={numPages!}
                min={1}
                onChange={(e: any) => {
                  const newPage = Math.min(e.value, numPages!);
                  if (newPage !== pageNumber) {
                    changePage(newPage);
                  }
                }}
              />
              z {numPages}
            </div>
            <Button
              className={'btn-blue'}
              onClick={() => changePage(pageNumber + 1)}
              disabled={pageNumber >= (numPages || 1)}
            >
              Další
            </Button>
          </div>
        </div>

        <div className={'relative'}>
          <div className={'flex items-stretch justify-between gap-2 mb-1'}>
            {lastUpdateDate && lastUpdateAuthor && (
              <div className={'flex items-center border px-3 py-2 text-sm bg-[#ffedd5] border-[#f6dbb6]'}>
                <span>
                  Poslední úprava: <strong>{lastUpdateAuthor}</strong> | <strong>{lastUpdateDate}</strong>
                </span>
              </div>
            )}
            <Button
              icon={<PlusIcon size={18} />}
              className={'btn-secondary text-xs px-2 py-2'}
              iconPos={'right'}
              onClick={() => openNewSwitchboardDialog()}
            >
              Přidat&nbsp;zařízení
            </Button>
          </div>
          <FileTable
            fileData={getCurrentPageData}
            fileId={fileId}
            page={pageNumber - 1}
            key={`file-table-${pageNumber}`}
          />
        </div>
      </div>

      <Dialog
        draggable={false}
        style={{ width: '25rem' }}
        header="Přidat nové zařízení"
        visible={newSwitchboardDialogVisible}
        onHide={() => setNewSwitchboardDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex flex-col">
            <label htmlFor="name">Název zařízení</label>
            <InputText
              id="name"
              value={newSwitchboard.identifier_name}
              onChange={(e) => setNewSwitchboard({ ...newSwitchboard, identifier_name: e.target.value })}
            />
          </div>
        </div>
        <div className={'flex items-center justify-between'}>
          <Button label="Zrušit" onClick={() => setNewSwitchboardDialogVisible(false)} className={'btn-cancel'} />
          <Button label="Přidat" onClick={addNewSwitchboard} autoFocus={true} className={'btn-secondary'} />
        </div>
      </Dialog>

      <Dialog
        draggable={false}
        style={{ width: '25rem' }}
        header="Přejmenovat soubor"
        visible={renameDialogVisible}
        onHide={() => setRenameDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex flex-col">
            <label htmlFor="filename">Název souboru</label>
            <InputText
              id="filename"
              value={renameFile.filename ? renameFile.filename : fileData?.filename}
              onChange={(e) => setRenameFile({ ...renameFile, filename: e.target.value })}
            />
          </div>
        </div>
        <div className={'flex items-center justify-between'}>
          <Button label="Zrušit" onClick={() => setRenameDialogVisible(false)} className={'btn-cancel'} />
          <Button label="Přejmenovat" onClick={renameFileAction} autoFocus={true} className={'btn-secondary'} />
        </div>
      </Dialog>

      <Dialog
        header="Vložit data na stránku"
        visible={pasteDialogVisible}
        onHide={() => setPasteDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex gap-2 items-center">
            <label htmlFor="withData">Včetně grafiky?</label>
            <Checkbox inputId="withData" checked={withData} onChange={(e: CheckboxChangeEvent) => setWithData(e.checked ?? false)} />
          </div>

          <div className={'flex items-center justify-between'}>
            <Button label="Zavřít" onClick={() => setPasteDialogVisible(false)} className={'btn-cancel'} />
            <Button label="Vložit" onClick={handlePasteData} autoFocus={true} className={'btn-secondary'} />
          </div>
        </div>
      </Dialog>
    </>
  );
};

export const Route = createFileRoute('/file/$fileId/')({
  component: FilePage,
});

export default FilePage;
