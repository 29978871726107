import api from '@/api/api';
import { useApiGet } from '@/api/apiRequests';
import { getFiles } from '@/api/services/File';
import { PageLoader } from '@/components/PageLoader';
import { RefetchProvider } from '@/contexts/RefetchContext';
import { formatDate } from '@/hooks/useDate.ts';
import { createFileRoute } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { FileUpload, FileUploadHeaderTemplateOptions, FileUploadSelectEvent } from 'primereact/fileupload';
import { FC, useRef, useState } from 'react';

export const IndexPage: FC = () => {
  const [isLoaderVisible, setIsLoaderVisible] = useState(false);
  const fileUploadRef = useRef<FileUpload>(null);
  const iniUploadRef = useRef<FileUpload>(null);
  const [fileMap, setFileMap] = useState([]);
  const navigate = useNavigate();
  const { data: files, isLoading } = useApiGet(['files'], () => getFiles(), {
    refetchOnWindowFocus: false,
    retry: 0,
  });

  const itemTemplate = (inFile: object) => {
    const file = inFile as File;
    return (
      <div className="flex items-center justify-center">
        <span>{file.name}</span>
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="mt-2 w-full flex flex-col items-center justify-center gap-2">
        <span className={'text-[#bfc5d0] text-md'}>Přetáhněte soubor zde</span>
      </div>
    );
  };

  const templateSelect = (event: FileUploadSelectEvent, name: string) => {
    setFileMap((prevFileMap: any) => {
      const newFileMap = { ...prevFileMap };
      newFileMap[name] = event.files;
      return newFileMap;
    });
  };

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { className, chooseButton } = options;

    return (
      <div className={className} style={{ backgroundColor: 'transparent', display: 'flex', alignItems: 'center' }}>
        {chooseButton}
      </div>
    );
  };

  const customUploader = async () => {
    const formData = new FormData();

    for (const [name, files] of Object.entries(fileMap)) {
      (files as File[]).forEach((file: any) => {
        formData.append(name, file);
      });
    }

    setIsLoaderVisible(true);

    try {
      const response = await api.post('upload', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      navigate({ to: '/file/$fileId', params: { fileId: response.data.uid } });
      setIsLoaderVisible(false);
    } catch (error) {
      console.error('Error uploading file', error);
      setIsLoaderVisible(false);
    }
  };

  const sendFiles = () => {
    if (fileUploadRef.current) {
      fileUploadRef.current.upload();
    }
    if (iniUploadRef.current) {
      iniUploadRef.current.upload();
    }

    customUploader();
  };

  const actionBodyTemplate = (rowData: any) => {
    return (
      <Button
        className="p-button-text text-sm bg-gray-100 hover:bg-gray-200 focus:border-0 focus:shadow-none"
        onClick={() => navigate({ to: '/file/$fileId', params: { fileId: rowData.uid } })}
      >
        Zobrazit soubor
      </Button>
    );
  };

  const dateBodyTemplate = (rowData: any) => {
    return formatDate(rowData.latest_inner_update);
  };

  return (
    <div className={'flex items-start gap-8'}>
      <RefetchProvider>
        <div className={'flex flex-col gap-4 w-full'}>
          <div className={'flex w-full gap-6 max-w-full mx-auto'}>
            <FileUpload
              className={'w-full mb-4 flex-1'}
              ref={fileUploadRef}
              name="pdf_file"
              chooseLabel={'Vybrat PDF soubor'}
              multiple={false}
              customUpload={true}
              maxFileSize={50000000}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              onSelect={(e) => templateSelect(e, 'pdf_file')}
              headerTemplate={headerTemplate}
            />
            <FileUpload
              className={'w-full flex-1'}
              ref={iniUploadRef}
              name="ini_file"
              chooseLabel={'Vybrat INI soubor'}
              multiple={false}
              customUpload={true}
              maxFileSize={50000000}
              itemTemplate={itemTemplate}
              emptyTemplate={emptyTemplate}
              onSelect={(e) => templateSelect(e, 'ini_file')}
              headerTemplate={headerTemplate}
            />
          </div>
          <Button className={'btn-secondary flex mx-auto'} onClick={sendFiles}>
            Zpracovat soubory
          </Button>

          <div className={'mt-8'}>
            <h2 className={'font-bold mb-2'}>Historie zpracovaných souborů</h2>
            <DataTable
              value={files}
              rows={20}
              paginator={true}
              sortField={'latest_inner_update'}
              sortOrder={-1}
              loading={isLoading}
              emptyMessage={'Žádné soubory'}
              className={'dashboard-table'}
            >
              <Column field="filename" header="Název souboru" className={'first-col'} />
              <Column field="page_count" header="Stránek" className={'text-center'} />
              <Column
                field="latest_inner_update"
                header="Poslední úprava"
                className={'text-center'}
                body={dateBodyTemplate}
              />
              <Column field="latest_inner_update_author" header="Autor poslední úpravy" className={'text-center'} />
              <Column className={'text-end'} body={actionBodyTemplate} />
            </DataTable>
          </div>
        </div>
      </RefetchProvider>
      <PageLoader visible={isLoaderVisible} />
    </div>
  );
};

export const Route = createFileRoute('/')({
  component: IndexPage,
});

export default IndexPage;
