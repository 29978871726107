export const formatDate = (dateString: string) => {
  let date: Date;

  if (/^\d{4}-\d{2}-\d{2}-\d{2}-\d{2}-\d{2}$/.test(dateString)) {
    // Handle the custom format 'YYYY-MM-DD-HH-MM-SS'
    const [year, month, day, hour, minute, second] = dateString.split('-').map(Number);
    date = new Date(year, month - 1, day, hour, minute, second);
  } else {
    // Fallback to default Date parsing
    date = new Date(dateString);
  }

  return new Intl.DateTimeFormat('cs-CZ', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  }).format(date);
};
