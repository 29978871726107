import { useApiGet } from '@/api/apiRequests';
import { getFileData } from '@/api/services/File.ts';
import { useQueryClient } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useCallback, useRef, useState } from 'react';

export const useCachedFileData = (fileId: string, page: number) => {
  const cancelTokenSourceRef = useRef<any>(null);
  const [suppressError, setSuppressError] = useState(false);
  const queryClient = useQueryClient();

  const {
    data: fileData,
    refetch: originalRefetch,
    isLoading,
    error: originalError,
  } = useApiGet<any, AxiosError>(
    ['fileData', fileId],
    async () => {
      setSuppressError(false);
      if (cancelTokenSourceRef.current) {
        cancelTokenSourceRef.current.cancel('Operation canceled due to new request.');
      }

      cancelTokenSourceRef.current = axios.CancelToken.source();

      try {
        return await getFileData(fileId, page, { cancelToken: cancelTokenSourceRef.current.token });
      } catch (error) {
        if (axios.isCancel(error)) {
          setSuppressError(true);
        }
        throw error;
      }
    },
    {
      refetchOnWindowFocus: false,
      retry: 0,
      enabled: false,
      staleTime: 0,
    }
  );

  // Custom error handler
  const error = suppressError ? null : originalError;

  const refetch = useCallback(async () => {
    await queryClient.invalidateQueries({ queryKey: ['fileData', fileId] });
    return originalRefetch();
  }, [queryClient, fileId, originalRefetch, page]);

  const compareAndFetch = useCallback(() => {
    return refetch();
  }, [refetch]);

  const compareAndFetchStatus = useCallback(async () => {
    await refetch();
    return fileData?.pages || null;
  }, [refetch, fileData]);

  return {
    fileData: fileData || null,
    isLoading,
    compareAndFetch,
    compareAndFetchStatus,
    error,
    refetch,
  };
};
