import api from '@/api/api.ts';
import { useUser } from '@/contexts/UserContext';
import { useCachedFileData } from '@/hooks/useCachedFileData';
import { Edit2Icon, PlusCircleIcon, RotateCcw, Trash2Icon } from 'lucide-react';
import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { FC, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useSearch } from '@tanstack/react-router';

interface FileTableProps {
  fileData: any;
  fileId: string;
  page: number;
}

const FileTable: FC<FileTableProps> = ({ fileData, fileId, page }) => {
  const { currentUser } = useUser();
  const [tableData, setTableData] = useState<any>(undefined);
  const [dynamicColumns, setDynamicColumns] = useState<any[]>([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [editingSwitchboard, setEditingSwitchboard] = useState<string | null>(null);
  const [currentSwitchboard, setCurrentSwitchboard] = useState<string>('');
  const [localEditValue, setLocalEditValue] = useState<string>('');
  const [inputValue, setInputValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentTablePage, setCurrentTablePage] = useState<number>(1);
  const [newPin, setNewPin] = useState({
    signal_name: '',
    signal_type: '',
    connector_name: '',
    line_number: '',
  });

  const navigate = useNavigate();
  const search = useSearch({ from: `/file/$fileId/` }) as { page_table?: string };
  const rowsPerPage = 12;
  const { refetch } = useCachedFileData(fileId, page);

  // Initialize the current page from URL on component mount
  useEffect(() => {
    const pageFromUrl = search.page_table ? parseInt(search.page_table as string, 10) : 1;
    setCurrentTablePage(pageFromUrl);
  }, [search.page_table]);

  useEffect(() => {
    setIsLoading(true);
    const currentPageData = fileData();
    if (!currentPageData || !currentPageData.switchboards) {
      setIsLoading(false);
      return;
    }

    const switchboards = currentPageData.switchboards;

    const data: any[] = [];
    const allFields = new Set<string>();

    switchboards.forEach((switchboard: any) => {
      if (switchboard.pins.length === 0) {
        // Add a row for switchboards with no pins, but mark it as a placeholder
        data.push({
          switchboard: switchboard.identifier.name,
          switchboard_uid: switchboard.identifier.uid,
          isEmptySwitchboard: true, // Add this flag to identify empty switchboards
        });
      } else {
        switchboard.pins.forEach((pin: any) => {
          const row: Record<string, string | boolean> = {
            switchboard: switchboard.identifier.name,
            switchboard_uid: switchboard.identifier.uid,
            binned: pin.binned,
            pin_uid: pin.uid,
            pin_author: pin.author,
            isEmptySwitchboard: false,
          };

          Object.entries(pin).forEach(([key, value]: [string, any]) => {
            if (key !== 'line_shape' && value?.name) {
              const columnKey = key;
              row[columnKey] = value.name;
              row[`${columnKey}_uid`] = value.uid;
              allFields.add(columnKey);
            }
          });

          data.push(row);
        });
      }
    });

    const fieldHeaderMap: Record<string, string> = {
      signal_name: 'Název',
      signal_type: 'Typ',
      connector_name: 'Konektor',
      line_number: 'Vodič',
      pin_author: 'Autor',
    };

    // Set specific sort of columns based on the fields present in the data
    const orderedFields = ['signal_name', 'signal_type', 'connector_name', 'line_number', 'pin_author'];

    // Create columns based on the ordered fields
    const columns = orderedFields.map((field) => ({
      field,
      header: fieldHeaderMap[field] || field,
    }));

    setTableData(data);
    setDynamicColumns(columns);
    setIsLoading(false);
  }, [fileData]);

  const onTablePageChange = (event: any) => {
    const newPage = event.page + 1; // PrimeReact uses 0-based indexing
    setCurrentTablePage(newPage);

    // Update URL with the new page parameter
    navigate({
      to: `/file/${fileId}`,
      search: (prev) => {
        return { ...prev, page_table: newPage };
      },
      replace: true,
    });
  };

  const headerTemplate = (data: any) => {
    return (
      <div className="flex items-center justify-between gap-2">
        {editingSwitchboard === data.switchboard_uid ? (
          <div className="flex items-center gap-2">
            <InputText
              value={localEditValue}
              onChange={(e) => setLocalEditValue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSwitchboardNameChange(localEditValue, data.switchboard_uid);
                } else if (e.key === 'Escape') {
                  setEditingSwitchboard(null);
                }
              }}
              autoFocus={true}
            />
          </div>
        ) : (
          <div className="flex items-center gap-2">
            <span className="font-bold">{data.switchboard}</span>
            <Button
              icon={<Edit2Icon size={16} />}
              className="p-button-text p-button-rounded text-black hover:text-blue-700 hover:bg-none focus:border-0 focus:shadow-none"
              onClick={() => {
                setEditingSwitchboard(data.switchboard_uid);
                setLocalEditValue(data.switchboard);
              }}
            />
          </div>
        )}
        <div className={'flex items-center gap-1'}>
          <Button
            icon={<Trash2Icon size={20} />}
            className="p-button-text p-button-rounded text-black hover:text-red-700 hover:bg-none focus:border-0 focus:shadow-none"
            onClick={() => handlePinAction('list', data.switchboard_uid)}
            disabled={data.isEmptySwitchboard} // Disable for empty switchboards
          />
          <Button
            icon={<PlusCircleIcon size={20} />}
            className="p-button-text p-button-rounded text-black hover:text-blue-700  hover:bg-none focus:border-0 focus:shadow-none"
            onClick={() => openNewPinDialog(data.switchboard_uid)}
          />
        </div>
      </div>
    );
  };

  const handleSwitchboardNameChange = async (newName: string, switchboardUid: string) => {
    try {
      const response = await api.put(`switchboard/${switchboardUid}`, { identifier_name: newName });
      if (response.status === 200 || response.status === 202) {
        toast.success('Název zařízení úspěšně změněn.');
        await refetch();
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při změně názvu zařízení.`);
      }
    }
    setEditingSwitchboard(null);
  };

  const openNewPinDialog = (switchboard: string) => {
    setCurrentSwitchboard(switchboard);
    setDialogVisible(true);
  };

  const addNewPin = async () => {
    try {
      const response = await api.post(`add/pin/${currentSwitchboard}`, { ...newPin });
      if (response.status === 200 || response.status === 202) {
        toast.success('Nový řádek úspěšně přidán.');
        setDialogVisible(false);
        await refetch();
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při ukládání řádku.`);
      }
    }
  };

  const switchboardBodyTemplate = () => {
    // Return an empty element to hide the Switchboard value in table rows
    return null;
  };

  const onCellEditComplete = ({
    rowData,
    newValue,
    field,
  }: {
    rowData: any;
    newValue: string;
    field: string;
  }) => {
    const currentValue = rowData[field];

    if (inputValue.length === 0) {
      return;
    }

    // Check if the new value is different from the original value
    if (currentValue !== inputValue && inputValue.length > 0) {
      api
        .put(`pin/${rowData.pin_uid}`, { [field]: newValue })
        .then(() => {
          // Update the local state to reflect the changes
          const updatedTableData = tableData.map((row: any) => {
            if (row.pin_uid === rowData.pin_uid) {
              return {
                ...row,
                [field]: newValue,
                pin_author: `${currentUser?.given_name} ${currentUser?.family_name}`,
              };
            }
            return row;
          });
          setTableData(updatedTableData);
          toast.success('Pole úspěšně aktualizováno.', { autoClose: 1000, position: 'top-right' });
        })
        .catch((error) => {
          console.error('Error updating field', error);
          toast.error('Chyba při úpravě pole.', { autoClose: 3000, position: 'top-right' });
        });
    }

    setInputValue('');
  };

  const handlePinAction = async (action: 'list' | 'bin' | 'unbin', data: any) => {
    try {
      switch (action) {
        case 'list': {
          const pinsUnderSwitchboard = tableData.filter((row: any) => row.switchboard_uid === data);
          if (pinsUnderSwitchboard.length > 0) {
            const pinUids = pinsUnderSwitchboard.map((pin: any) => pin.pin_uid);
            const endpoint = action === 'list' ? 'bin/pins' : 'unbin/pins';
            const response = await api.post(endpoint, pinUids);
            if (response.status === 202 || response.status === 200) {
              const actionText = action === 'list' ? 'odebrání' : 'přidání';
              toast.success(`Zařízení označeno k ${actionText}.`);
              await refetch();
            }
          } else {
            toast.info('Žádné řádky nebyly nalezeny.');
          }
          break;
        }

        case 'bin':
        case 'unbin': {
          const endpoint = action === 'bin' ? 'bin/pins' : 'unbin/pins';
          const response = await api.post(endpoint, [data.pin_uid]);
          if (response.status === 202 || response.status === 200) {
            const actionText = action === 'bin' ? 'odebrání' : 'přidání';
            toast.success(`Řádek označen k ${actionText}.`);
            await refetch();
          }
          break;
        }

        default:
          throw new Error('Chybná akce');
      }
    } catch (error: any) {
      if (error?.response?.data?.detail) {
        toast.error(`Error: ${error.response.data.detail}`);
      } else {
        toast.error(`Chyba při provádění akce.`);
      }
    }
  };

  const actionBodyTemplate = (rowData: any) => {
    // For empty switchboards, don't render any action buttons
    if (rowData.isEmptySwitchboard) {
      return null;
    }

    if (rowData.binned) {
      return (
        <Button
          icon={<RotateCcw size={16} />}
          className="p-button-text p-button-rounded p-button-info  hover:bg-none focus:border-0 focus:shadow-none"
          onClick={() => handlePinAction('unbin', rowData)}
        />
      );
    }
    if (!rowData.binned) {
      return (
        <Button
          icon={<Trash2Icon size={16} />}
          className="p-button-text p-button-rounded p-button-danger  hover:bg-none focus:border-0 focus:shadow-none"
          onClick={() => handlePinAction('bin', rowData)}
        />
      );
    }
    return null;
  };

  return (
    <div>
      <DataTable
        value={tableData}
        rowGroupMode="subheader"
        groupRowsBy="switchboard_uid"
        sortMode="single"
        sortField="switchboard"
        rows={rowsPerPage}
        paginator={true}
        first={(currentTablePage - 1) * rowsPerPage}
        onPage={onTablePageChange}
        loading={isLoading || tableData === undefined}
        className={'dataTable-detail'}
        emptyMessage={'Žádná data'}
        rowGroupHeaderTemplate={headerTemplate}
      >
        <Column
          field="switchboard"
          header="Zařízení"
          body={switchboardBodyTemplate}
          className={'actionCol'}
          style={{ fontWeight: 'bold' }}
          bodyStyle={{ textAlign: 'center' }}
        />
        {dynamicColumns.map((col) => (
          <Column
            key={col.field}
            field={col.field}
            className={col.field === 'pin_author' ? 'authorCol' : ''}
            header={col.header}
            style={{ minWidth: '6rem' }}
            body={(rowData) => {
              // For empty switchboards, don't render any input fields
              if (rowData.isEmptySwitchboard) {
                return null;
              }

              return col.field !== 'pin_author' ? (
                <InputText
                  defaultValue={rowData[col.field] || ''}
                  onChange={(e) => setInputValue(e.target.value)}
                  onBlur={(e) => {
                    const newValue = e.target.value;
                    onCellEditComplete({ rowData, newValue, field: col.field });
                  }}
                />
              ) : (
                <span>{rowData[col.field]}</span>
              );
            }}
            bodyClassName={(rowData) => {
              if (rowData.isEmptySwitchboard) {
                return 'hidden'; // Hide cells for empty switchboards
              }
              if (col.field === 'pin_author' && rowData[col.field] !== null) {
                const isAuthor = `${currentUser?.given_name} ${currentUser?.family_name}` !== rowData[col.field];
                return isAuthor ? 'bg-orange-100' : '';
              }
              return '';
            }}
          />
        ))}
        <Column className={'actionCol'} body={actionBodyTemplate} style={{ width: '4rem' }} />
      </DataTable>

      <Dialog
        draggable={false}
        visible={dialogVisible}
        header="Přidat nový řádek"
        style={{ width: '25rem' }}
        className={'customDialog'}
        onHide={() => setDialogVisible(false)}
      >
        <div className="flex flex-col gap-4 mb-5">
          <div className="flex flex-col">
            <label htmlFor="signal_name">Název</label>
            <InputText
              id="signal_name"
              value={newPin.signal_name}
              onChange={(e) => setNewPin({ ...newPin, signal_name: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="signal_type">Typ</label>
            <InputText
              id="signal_type"
              value={newPin.signal_type}
              onChange={(e) => setNewPin({ ...newPin, signal_type: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="connector_name">Konektor</label>
            <InputText
              id="connector_name"
              value={newPin.connector_name}
              onChange={(e) => setNewPin({ ...newPin, connector_name: e.target.value })}
            />
          </div>
          <div className="flex flex-col">
            <label htmlFor="line_number">Vodič</label>
            <InputText
              id="line_number"
              value={newPin.line_number}
              onChange={(e) => setNewPin({ ...newPin, line_number: e.target.value })}
            />
          </div>
        </div>
        <div className={'flex items-center justify-between'}>
          <Button label="Zrušit" onClick={() => setDialogVisible(false)} className={'btn-cancel'} />
          <Button label="Přidat" onClick={addNewPin} autoFocus={true} className={'btn-secondary'} />
        </div>
      </Dialog>
    </div>
  );
};

export default FileTable;
